<template>
  <div class="box">
    <!-- <top-bar :title='this.$route.query.title' :left="true"></top-bar> -->
    <div class="workGoal">
      <van-cell-group border>
        <div style="height: 50px; display: flex; align-items: center">
          <div style="
              height: 16px;
              width: 3px;
              background-color: #387df4;
              margin-left: 20px;
            "></div>
          <div style="
              font-size: 18px;
              color: black;
              font-weight: 600;
              margin-left: 5px;
            ">
            {{this.$route.query.title}}任务详情
          </div>
        </div>
        <van-cell title=" 任务标题">
          <div style="float: right">{{ workInfo.title }}</div>
        </van-cell>
        <van-cell title=" 目标类别">
          <div style="float: right">{{ category[workInfo.category] }}</div>
        </van-cell>
        <van-cell title=" 认领人">
          <div style="float: right;
            height:22.4px;
            width: 100%;
            text-align:right;
           overflow:scroll;">{{ workInfo.getUser }}</div>
        </van-cell>
        <van-cell title=" 完成状态">
          <div v-if="workInfo.finishStatus == 1" style="float: right">
            未完成
          </div>
          <div v-if="workInfo.finishStatus == 0" style="float: right">
            已完成
          </div>
        </van-cell>
        <van-cell v-if="workInfo.finishStatus == 0" title=" 完成时间">
          <div style="float: right">{{ workInfo.finishTime }}</div>
        </van-cell>

        <van-cell v-if="workInfo.finishStatus == 1" title=" 预计完成时间">
          <div style="float: right">{{ workInfo.finishTime }}</div>
        </van-cell>

        <div style="height: 530px">
          <van-cell title="  任务详情描述"> </van-cell>
          <div class="content">
            {{ workInfo.workDesc }}
          </div>
        </div>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";

export default {
  components: {
    topBar,
  },
  data() {
    return {
      category: {
        0: "近期工作",
        1: "今年工作",
        2: "五年工作",
      },
      workInfo: {},
    };
  },
  methods: {
    getDataInfo(userId) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appjqworktarget/info/" + userId),
        method: "post",
      }).then(
          ({ data }) => {
            if (data.code == 0) {
              // 加载状态结束
              this.workInfo = data.appJqWorkTarget;
              this.loading = false;
              this.$toast.clear();
            }
            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
      );
    },
  },
  mounted() {

    this.getDataInfo(this.$route.query.userId);
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f6f5f3;
}
.workGoal {
  width: 100vw;
  background-color: #f6f5f3;
}
.content {
  padding: 30px;
  margin: 15px auto;
  font-size: 24px;
  color: #646566;
}
</style>
